import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Photo Sharing App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/photo-sharing-mobile-app/"
    metaDescription="Looking to design a photo sharing app? Say hello to Moment, a photo sharing app template by Uizard. Sign up to Uizard today and bring your idea to life!"
    description="
    h2:Introducing Moment, a photo sharing app design template for mobile
    <br/>
    Looking to design a photo sharing app? Say hello to Moment, a photo sharing app <a:https://uizard.io/templates/>UI design template</a> to use as a launch pad for your next project. Whether you're a seasoned UX designer or a complete novice, our premade templates come with everything you could possibly need to get your idea off the ground.
    <br/>
    h3:Photo sharing app UI design made easy
    <br/>
    Uizard makes <a:https://uizard.io/templates/mobile-app-templates/>app design</a> easier than ever before with its easy-to-use, drag-and-drop editor. Add news screens to your design and populate them with a wide range of UI components, or use our template exactly as it comes right out of the box.
    <br/>
    h3:Bring your ideas to life with the power of AI
    <br/>
    Want to fast-track the design process even more? Uizard’s <a:https://uizard.io/design-assistant/>AI design</a> features provide you with a whole host of options to help you expedite your project. Use Text Assistant to generate new CTA text or import screenshots from other websites or apps and transform them into editable designs.
    "
    pages={[
      "Sign-in flow example for your prospective users",
      "Image display screen showcasing how users will share and display their images",
      "An image feed mockup demonstrating how users will follow and interact with each other",
      "A profile-sharing screen with an image calendar",
    ]}
    projectCode="VRLlx7rXv0crp91jlgnb"
    img1={data.image1.childImageSharp}
    img1alt="Photo sharing social media mobile app summary"
    img2={data.image2.childImageSharp}
    img2alt="Photo sharing mobile app sign in flow example"
    img3={data.image3.childImageSharp}
    img3alt="Image display screen showcasing how users will share and display their images"
    img4={data.image4.childImageSharp}
    img4alt="Image feed mockup demonstrating how users will follow and interact with each other"
    img5={data.image5.childImageSharp}
    img5alt="Profile sharing screen with image calendar"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/photo-sharing-social-media-mobile-app/photo-sharing-mobile-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/photo-sharing-social-media-mobile-app/photo-sharing-mobile-app-welcome.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/photo-sharing-social-media-mobile-app/photo-sharing-mobile-app-post.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/photo-sharing-social-media-mobile-app/photo-sharing-mobile-app-feed.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/photo-sharing-social-media-mobile-app/photo-sharing-mobile-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
